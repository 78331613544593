<template>
    <nav class="inspireert-navigation-bar">
        <NuxtLink
            class="navigation-bar__logo"
            to="/"
        >
            <img
                src="~/assets/img/logo.svg"
                alt="Logo"
                loading="eager"
                fetchpriority="high"
                width="70"
                height="44"
            >
        </NuxtLink>

        <menu class="inspireert-navigation-bar__menu">
            <li>
                <BaseButton
                    to="/inspireert/programma"
                    :element="NuxtLink"
                    class="base-button--link"
                    size="large"
                >
                    Programma
                </BaseButton>
            </li>
            <li>
                <BaseButton
                    to="/inspireert/locatie"
                    :element="NuxtLink"
                    class="base-button--link"
                    size="large"
                >
                    Locatie
                </BaseButton>
            </li>
            <li>
                <BaseButton
                    to="/inspireert/sprekers"
                    :element="NuxtLink"
                    class="base-button--link"
                    size="large"
                >
                    Sprekers
                </BaseButton>
            </li>
            <li>
                <BaseButton
                    to="/account-aanmaken"
                    :element="NuxtLink"
                    size="large"
                >
                    Aanmelden
                </BaseButton>
            </li>
        </menu>
    </nav>
</template>

<script setup>
const NuxtLink = resolveComponent('NuxtLink');
</script>

<style lang="less" src="./InspireertNavBar.less"></style>
