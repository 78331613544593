<template>
    <DelayHydration>
        <footer
            class="inspireert-footer"
        >
            <div class="inspireert-footer__container">
                <div
                    class="inspireert-footer__content"
                >
                    <NuxtLink
                        class="inspireert-footer__logo"
                        to="/inspireert"
                    >
                        <InspireertLogo height="32" />
                    </NuxtLink>

                    <NuxtLink
                        to="/"
                        class="inspireert-footer__initiative"
                    >
                        <p>
                            Het ledenevent van <u>Transport en Logistiek Nederland</u>
                        </p>

                        <div
                            class="inspireert-footer__initiative-logo"
                        >
                            <img
                                src="~/assets/img/logo.svg"
                                alt="TLN Logo"
                            >
                        </div>
                    </NuxtLink>
                </div>
            </div>
        </footer>
    </DelayHydration>
</template>

<script setup>
</script>

<style lang="less" src="./InspireertFooter.less" />
