<template>
    <div class="layout--inspireert">
        <InspireertNavBar />
        <InspireertMenuOverlay />
        <InspireertHamburgerMenu />

        <slot />

        <InspireertFooter />
    </div>
</template>

<script setup>
import GlobalData from '~/graphql/queries/inspireert/GlobalData.graphql';
import { useInspireertStore } from '~/store/inspireert';

const inspireertStore = useInspireertStore();

const { data: globalData } = await useAsyncQuery({
    query: GlobalData
});

inspireertStore.setGlobalData(toValue(globalData));

useHead({
    htmlAttrs: {
        lang: 'nl'
    },
    bodyAttrs: {
        'menu-open': inspireertStore.isMainMenuOpen ? 'true' : 'false',
    },
    link: [
        {
            rel: 'icon',
            type: 'image/x-icon',
            href: '/favicons/hlaml.ico'
        }
    ],
});
</script>

<style lang="less">
.layout--inspireert {
    display: grid;
    grid-template-rows: 1fr auto;
    grid-template-columns: 100%;
    min-height: 100vh;
}
</style>
