<template>
    <DnModal
        transition-name="inspireert-menu"
        :is-visible="inspireertStore.isMainMenuOpen"
        :hide-default-close-button="true"
        :prevent-body-scroll="true"
        class="menu-overlay inspireert-menu-overlay"
        @close="inspireertStore.closeMainMenu"
    >
        <div class="inspireert-menu-overlay__container">
            <NuxtLink
                class="inspireert-menu-overlay__logo"
                to="/inspireert"
            >
                <img
                    src="~/assets/img/inspireert/TYPE_TLN_INSPIREERT_BLAUW_v2.png"
                >
            </NuxtLink>

            <menu
                v-if="primaryMenu && primaryMenu.length"
                class="inspireert-navigation-links-primary"
            >
                <li
                    v-for="(link, index) in primaryMenu"
                    :key="`primary-menu-${index}`"
                    :data-text="link.linkField.text"
                >
                    <CraftLink :link="link.linkField">
                        {{ link.linkField.text }}
                    </CraftLink>
                </li>
            </menu>

            <menu
                v-if="secondaryMenu && secondaryMenu.length"
                class="inspireert-navigation-links-secondary"
            >
                <li
                    v-for="(link, index) in secondaryMenu"
                    :key="`primary-menu-${index}`"
                    :data-text="link.linkField.text"
                >
                    <CraftLink :link="link.linkField">
                        {{ link.linkField.text }}
                    </CraftLink>
                </li>
            </menu>
        </div>
    </DnModal>
</template>

<script setup>
import DnModal from '@digitalnatives/modal';
import { useInspireertStore } from '~/store/inspireert';

const inspireertStore = useInspireertStore();
const route = useRoute();

watch(() => route.path, () => {
    inspireertStore.closeMainMenu();
});

const primaryMenu = computed(() => {
    return inspireertStore.primaryMenu;
});

const secondaryMenu = computed(() => {
    return inspireertStore.secondaryMenu;
});
</script>

<style lang="less" src="./InspireertMenuOverlay.less"></style>
