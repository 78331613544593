<template>
    <div class="hamburger-menu inspireert-hamburger-menu">
        <BaseButton
            :class="{ 'open': mainMenuOpen }"
            type="button"
            class="hamburger-menu__button"
            icon-position="center"
            aria-label="Open menu"
            @click="inspireertStore.toggleMainMenu()"
        >
            <i class="icon-menu">
                <span />
                <span />
                <span />
                <span />
                <span />
            </i>
        </BaseButton>
    </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useInspireertStore } from '~/store/inspireert';

const inspireertStore = useInspireertStore();
const { mainMenuOpen } = storeToRefs(inspireertStore);
</script>

<style lang="less" src="./InspireertHamburgerMenu.less"></style>
