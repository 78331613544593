import { defineStore } from 'pinia';

export const useInspireertStore = defineStore('inspireert-store', {
    state: () => {
        return {
            mainMenuOpen: false,
            primaryMenuItems: [],
            secondaryMenuItems: [],
        };
    },

    getters: {
        primaryMenu() {
            return this.primaryMenuItems;
        },
        secondaryMenu() {
            return this.secondaryMenuItems;
        },
        isMainMenuOpen() {
            return this.mainMenuOpen;
        },
    },

    actions: {
        toggleMainMenu() {
            this.mainMenuOpen = !this.mainMenuOpen;
        },

        openMainMenu() {
            this.mainMenuOpen = true;
        },

        closeMainMenu() {
            this.mainMenuOpen = false;
        },

        setGlobalData(globalData) {
            if (!globalData) {
                return;
            }

            const inspireertNavigation = globalData?.inspireertNavigation[0];

            if (inspireertNavigation) {
                this.primaryMenuItems = inspireertNavigation.primaryNavigation;
                this.secondaryMenuItems = inspireertNavigation.secondaryNavigation;
            }
        },
    }
});
